import React from "react";
import {withTranslation} from "react-i18next";
import Slider from "react-slick";  //eslint-disable-line no-unused-vars
import axios from "axios";
import { Link } from 'react-router-dom';
import RoutePath from '../../resource/RoutePath'

export const DevServer = "https://dev-apis.981park.net"
export const RealServer = "https://apis-jeju.981park.com"

const headers = {
	'Content-Type': 'application/json;charset=UTF-8',
	'X-Authorization-Client-Secret': '586f474c-50b1-11eb-b88b-02d92b41ce80',
	'X-Authorization-Client-Id': '981park-customer-home'
}

class NewMGrocComp  extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ddayT: "0",
			ddayN: "0",
			ddayCheck: false,
			bannerCheck: true,
			tabType: "best",
			roundDropDown: false,
			bestProfilePopup: false,
			selectBestProfile: null,
			selectRoundProfile: null,
			roundProfilePopup: false,
			pointList: [],
			pointOnOffList: [],
			currentRound: null,
			removeAni: false,
			toggle: false,
			bestRanking: [],
			roundRanking: [],
			realRanking: []
		};
		var season = null  //eslint-disable-line no-unused-vars
		var schId = null  //eslint-disable-line no-unused-vars
		var roundList = [];  //eslint-disable-line no-unused-vars
	}

	componentDidMount() {
		var today = new Date()
		var dday = new Date('2024-12-01')
		var params = new URLSearchParams(window.location.search);
		if (params.get("today") !== null) {
			dday = new Date(params.get("today"))
		}
		var gap = dday.getTime() - today.getTime()
		var result = Math.ceil(gap /(1000 * 60 * 60 * 24))
		if (result <= 0) {
			this.setState({ddayCheck: true},  this.animationStart("real"))
		}

		var str = result.toString()
		if (str.length === 1 || str === 0) {
			this.setState({ddayT: "day",ddayN: ""})
		} else if (str.length === 1) {
			this.setState({ddayT: "0",ddayN: str})
		} else {
			this.setState({ddayT: str.charAt(0), ddayN: str.charAt(1)})
		}

		/*if (window.location.hostname === "localhost" || window.location.hostname === "dev-home.981park.net") {
			this.server = DevServer
		} else {
			this.server = RealServer
		}*/

		this.server = RealServer

		this.requestBest()
		this.requestSeason()
		this.requestRealTimeCheck(true)

		this.aniInterval = setInterval(() => {
			this.setState({removeAni: true})
			clearInterval(this.aniInterval)
		}, 5000);
		window.scrollTo(1, 0);
	}

	requestRealTimeCheck(first) {
		axios({
			url:`${this.server}/customer/data/groc/main`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.schId = response.data.data.schId
			this.requestRealTimeRanking(first)
		})
	}

	requestRealTimeRanking(first) {
		axios({
			url:`${this.server}/customer/data/groc/ranking?schId=${this.schId}`,
			method: 'get',
			headers: headers
		}).then(response => {
			if (first === true) {
				this.setState({realRanking: response.data.data.rankingTotal.rankingList})
			} else {
				this.setState({realRanking: response.data.data.rankingTotal.rankingList, tabType: "scrollReset"}, this.changeTap("real"))
			}
			// var list = response.data.data.rankingTotal.rankingList
			// const numAscending = [...list].sort((a, b) => {
			// 	if (a.xlLaptime === null || a.xlLaptime === 0) {
			// 		return true
			// 	}else if (b.xlLaptime === null || b.xlLaptime === 0){
			// 		return false
			// 	} else {
			// 		return a.xlLaptime - b.xlLaptime
			// 	}

			// });
			// if (first === true) {
			// 	this.setState({realRanking: numAscending})
			// } else {
			// 	this.setState({realRanking: numAscending, tabType: "scrollReset"}, this.changeTap("real"))
			// }
		})
	}

	requestBest() {
		axios({
			url:`${this.server}/customer/data/groc/bestLapRanking?offset=0&limit=100`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.setState({bestRanking: response.data.data.rankingList})
		})

	}

	requestSeason() {
		axios({
			url:`${this.server}/customer/data/groc/seasons`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.season = response.data.data[response.data.data.length-1];
			this.requestRoundList()
		})
	}

	requestRoundList() {
		axios({
			url:`${this.server}/customer/data/groc/rounds?seaId=${this.season.seaId}`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.roundList = response.data.data;
			if (this.roundList.length > 1) {
				this.roundList.shift()
				if (this.roundList[this.roundList.length - 1].roundName.includes("Post-Round")) {
					this.roundList.pop()
				}
			}
			this.setState({currentRound:this.roundList[this.roundList.length - 1]}, this.requestRoundRanking)
		})
	}

	requestRoundRanking(rndId) {
		if (this.roundList.length > 0) {
			axios({
				url:`${this.server}/customer/data/groc/roundRanking?rndId=${this.state.currentRound.rndId}&lgeId=3&offset=0&limit=20`,
				method: 'get',
				headers: headers
			}).then(response => {
				if (response.data.data != null) {
					if (response.data.data.length > 10) {
						this.setState({roundRanking: response.data.data.splice(0, 10)})
					} else {
						this.setState({roundRanking: response.data.data})
					}
				} else {
					this.setState({roundRanking: ''})
				}
			})
		}
	}

	animationStart = (tabType) => () => {
		if (tabType == "real") {
			this.setState({tabType: "scrollReset"}, this.changeTap(tabType))
			this.realInterval = setInterval(() => {
				this.requestRealTimeCheck(false)
			}, 60000);
		} else if (this.state.tabType != tabType) {
			clearTimeout(this.realInterval)
			this.setState({tabType: "scrollReset"}, this.changeTap(tabType))
		}
	}

	changeTap = (tabType) => () => {
		this.setState({tabType: tabType, toggle: true}, this.animationEnd)
	}

	showBestPopup = (ranking) => () => {
		this.setState({selectBestProfile: ranking, bestProfilePopup: true})
	}

	hideBestPopup = () => () => {
		this.setState({selectBestProfile: null, bestProfilePopup: false})
	}

	showRoundPopup = (round) => () => {
		axios({
			url:`${this.server}/customer/data/groc/points/${round.rndId}/3?memberId=${round.memberId}&raceRankingVersion=${round.raceRankingVersion}`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.setState({selectRoundProfile: round, pointList: response.data.data.details, pointOnOffList: Array(response.data.data.details.length).fill(false),roundProfilePopup: true})
		})

	}

	hideRoundPopup = () => () => {
		this.setState({selectRoundProfile: null, pointList: [], pointOnOffList: [], roundProfilePopup: false})
	}

	animationEnd = () => {
		this.listInterval = setInterval(() => {
			this.setState({toggle: false}, clearInterval(this.listInterval))
		}, 100);
	}

	selectRound = (round) => () => {
		this.setState({currentRound:round, roundDropDown: false, tabType: "scrollReset"}, () => {
			this.setState({tabType: "round"}, this.requestRoundRanking)
		})
	}


	openRoundDropDown = () => {
		this.setState({roundDropDown: !this.state.roundDropDown})
	}

	openClosePoint = (i) => () => {
		let copyArray = [...this.state.pointOnOffList];
		copyArray[i] = !copyArray[i]
		this.setState({pointOnOffList: copyArray})
	}

	getOrdinal(idx) {
		if (idx === null) {
			return ""
		} else if (idx === 1) {
			return "st"
		} else if (idx === 2) {
			return "nd"
		} else if (idx === 3) {
			return "rd"
		} else {
			return "th"
		}
	}

	convertTime(milli) {
		if (milli === 0) {
			return "N/A"
		} else {
			let time = new Date(milli);
			let minutes = time.getUTCMinutes();
			let seconds = time.getUTCSeconds();
			let milliseconds = time.getUTCMilliseconds();
			var secondStr = ""
			var milStr = ""
			if (milliseconds < 10) {
				milStr = "00" + milliseconds
			} else if (milliseconds < 100) {
				milStr = "0" + milliseconds
			} else {
				milStr = "" + milliseconds
			}
			if (seconds < 10) {
				secondStr = "0" + seconds
			} else {
				secondStr = "" + seconds
			}
			return minutes + "’" + secondStr + "”" + milStr;
		}
	}

	convertTimeReal(milli) {
		if (milli === null) {
			return "READY"
		}else if (milli === 0) {
			return "READY"
		} else {
			let time = new Date(milli);
			let minutes = time.getUTCMinutes();
			let seconds = time.getUTCSeconds();
			let milliseconds = time.getUTCMilliseconds();
			var secondStr = ""
			var milStr = ""
			if (milliseconds < 10) {
				milStr = "00" + milliseconds
			} else if (milliseconds < 100) {
				milStr = "0" + milliseconds
			} else {
				milStr = "" + milliseconds
			}
			if (seconds < 10) {
				secondStr = "0" + seconds
			} else {
				secondStr = "" + seconds
			}
			return minutes + "’" + secondStr + "”" + milStr;
		}
	}

	makeBest() {
		var rankings = []
		var len = this.state.bestRanking.length
		for (var i=0; i<len; i++) {
			var classN = "row"
			if (i < 3) {
				classN = classN + " groc-rk"
			}
			rankings.push(<div className={classN}>
				<ul>
					<li>
						<div className="rk">
							<span className="num typo2">{this.state.bestRanking[i].ranking}</span>
							<span className="label typo1">{this.getOrdinal(this.state.bestRanking[i].ranking)}</span>
						</div>
					</li>
					<li>
						<div className="rk-user">
							<div className="lab-time">
								<span className="num typo2">{this.convertTime(this.state.bestRanking[i].xlLaptime + this.state.bestRanking[i].xrLaptime)}</span>
							</div>
							<div className="name"><span className="txt">{this.state.bestRanking[i].nickName}</span></div>
						</div>
					</li>
					<li>
						<div className="btns">
							<button className="btn btn-popup" onClick={this.showBestPopup(this.state.bestRanking[i])}><span className="blind">자세히 보기</span></button>
						</div>
					</li>
				</ul>
			</div>)
		}

		return <div className={"tab-con best-raking " + (this.state.toggle ? "" : "on") + (this.state.removeAni ? "" : " visual-ani")}>
			<div className="visual-wrap">
				<div className="bg"></div>
				<div className="visual">
					<div className="ttl typo1">UNLIMIT <br/> YOUR <br/> LIMIT</div>
				</div>
				<div className="logo"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/graphic-emblem-groc-champion-2024.svg" alt=""/></div>
			</div>
			<div className="scroll-wrap">
				<div className="ranking-list">
					<div className="list">
						{rankings}
					</div>
				</div>
			</div>
		</div>
	}

	makeRound() {
		var rankings = []
		var classN = "row"
		if (true) {
			classN = classN + " groc-rk"
		}
		var len = this.state.roundRanking.length
		for (var i=0; i<len; i++) {
			rankings.push(<div className={classN}>
				<ul>
					<li>
						<div className="rk">
							<span className="num typo2">{this.state.roundRanking[i].ranking}</span>
							<span className="label typo1">{this.getOrdinal(this.state.roundRanking[i].ranking)}</span>
						</div>
					</li>
					<li>
						<div className="rk-user">
							<div className="lab-time">
								<span className="num typo2">{this.state.roundRanking[i].point}</span>
								<span className="label">RP</span>
							</div>
							<div className="name"><span className="txt">{this.state.roundRanking[i].nickName}</span></div>
						</div>
					</li>
					<li>
						<div className="btns">
							<button className="btn btn-popup" onClick={this.showRoundPopup(this.state.roundRanking[i])}><span className="blind">자세히 보기</span></button>
						</div>
					</li>
				</ul>
			</div>)
		}

		var round = []
		for (var i=0; i<this.roundList.length; i++) {
			round.push(<li><button type="button" onClick={this.selectRound(this.roundList[i])}>{this.roundList[i].roundName}</button></li>);
		}
		if (this.roundList !== null) {
			return <div className={"tab-con round-raking" + (this.state.toggle ? "" : " on")}>
				<div className="select-round-wrap">
					<div className="select-round">
						<button className={"btn-select" + (this.state.roundDropDown ? " on" : "")} onClick={() => this.openRoundDropDown()}>{this.state.currentRound.roundName}</button>
						<div className="list-round">
							<ul>
								{round}
							</ul>
						</div>
					</div>
					<div className="txt-area">
						<p>Finished {(this.state.currentRound.endDt).replaceAll('-', '.')}</p>
					</div>
				</div>
				<div className="scroll-wrap">
					<div className="ranking-list">
						<div className="list">
							{rankings}
						</div>
					</div>
				</div>
			</div>
		} else {
			return null
		}
	}

	makeReal() {
		var rankings = []
		var len = this.state.realRanking.length
		for (var i=0; i<len; i++) {
			var classN = "row"
			if (i < 3) {
				classN = classN + " groc-rk"
			}
			var rankStr = "-"
			var ordinalStr = ""
			if (this.state.realRanking[i].ranking !== null) {
				rankStr = this.state.realRanking[i].ranking + ""
				ordinalStr = this.getOrdinal(this.state.realRanking[i].ranking)
			}
			rankings.push(<div className={classN}>
				<ul>
					<li>
						<div className="rk">
							<span className="num typo2">{rankStr}</span>
							<span className="label typo1">{ordinalStr}</span>
						</div>
					</li>
					<li>
						<div className="rk-user">
							<div className="lab-time">
								<span className="num typo2">{this.state.realRanking[i].dnfYn ? "N/A" :  this.convertTimeReal(this.state.realRanking[i].xlLaptime + this.state.realRanking[i].xrLaptime)}</span>
							</div>
							<div className="name"><span className="txt">{this.state.realRanking[i].nickName}</span></div>
						</div>
					</li>
					<li>
						<div className="btns">
							<button className="btn btn-popup" onClick={this.showBestPopup(this.state.realRanking[i])}><span className="blind">자세히 보기</span></button>
						</div>
					</li>
				</ul>
			</div>)
		}

		return <div className={"tab-con best-raking " + (this.state.toggle ? "" : "on") + (this.state.removeAni ? "" : " visual-ani")}>
			<div className="visual-wrap">
				<div className="bg"></div>
				<div className="visual">
					<div className="ttl typo1">UNLIMIT <br/> YOUR <br/> LIMIT</div>
				</div>
				<div className="logo"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/graphic-emblem-groc-champion-2024.svg" alt=""/></div>
			</div>
			<div className="scroll-wrap">
				<div className="ranking-list">
					<div className="list">
						{rankings}
					</div>
				</div>
			</div>
		</div>
	}

	render() {
		const {t} = this.props;
		var contents = null
		var tabName = ""
		if (this.state.tabType === "best") {
			tabName = "selected"
			contents = this.makeBest()
		} else if (this.state.tabType === "round") {
			tabName = ""
			contents = this.makeRound()
		} else if (this.state.tabType === "real") {
			tabName = "selected"
			contents = this.makeReal()
		}
		var rankingTitle = ""
		if (this.state.ddayCheck === true) {
			rankingTitle = t('Groc_2023_ranking')
		} else {
			rankingTitle = t('Groc_best_lap_ranking')
		}

		var bestPopup = null
		if (this.state.bestProfilePopup) {
			var rankStr = "-"
			if (this.state.selectBestProfile.ranking !== null) {
				rankStr = this.state.selectBestProfile.ranking + this.getOrdinal(this.state.selectBestProfile.ranking)
			}
			if (this.state.ddayCheck !== true) {
				var srcString = ""
				if (this.state.selectBestProfile.image === null) {
					srcString = "https://asset.981park.com/www/common/mweb/img/sub/groc/icon-profile-empty-large.png"
				} else {
					srcString = this.state.selectBestProfile.image
				}
				bestPopup = <div className="layer-popup groc-popup groc-best-popup">
					<div className="layer-popup-inner">
						<div className="content">
							<button type="button" className="btn close" onClick={this.hideBestPopup()}><span className="blind">닫기</span></button>
							<div className="con">
								<div className="detail-ranking groc-rk">
									<div className="row rk-user">
										<div className="img-wrap"><span className="img"><img src={srcString} alt=""/></span></div>
										<div className="name"><span className="txt">{this.state.selectBestProfile.nickName}</span></div>
										<div className="lab-time"><span className="num">{this.convertTimeReal(this.state.selectBestProfile.xlLaptime + this.state.selectBestProfile.xrLaptime)}</span></div>
									</div>

									<div className="row">
										<div className="track">
											<span className="sm-txt">{t('Groc_l_lap')}</span>
											<span className="num">{this.convertTimeReal(this.state.selectBestProfile.xlLaptime)}</span>
										</div>
										<div className="track">
											<span className="sm-txt">{t('Groc_r_lap')}</span>
											<span className="num">{this.convertTimeReal(this.state.selectBestProfile.xrLaptime)}</span>
										</div>
									</div>

									<div className="row">
										<div className="ranking">
											<span className="sm-txt">{t('Groc_total')}</span>
											<span className="num">{rankStr}</span>
										</div>
										<div className="ranking">
											<span className="sm-txt">{t('Groc_round_rank')}</span>
											<span className="label rk1">
															<span className="round">{this.state.selectBestProfile.roundName.replaceAll(" ","")}</span>
															<span className="rk">{this.state.selectBestProfile.roundRanking + this.getOrdinal(this.state.selectBestProfile.roundRanking)}</span>
														</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			} else {
				var srcString = ""
				if (this.state.selectBestProfile.thumbnail === null) {
					srcString = "https://asset.981park.com/www/common/mweb/img/sub/groc/icon-profile-empty-large.png"
				} else {
					srcString = this.state.selectBestProfile.thumbnail
				}
				bestPopup = <div className={"layer-popup groc-popup groc-ranking-popup "  + (this.state.selectBestProfile.dnfYn ? "fs-popup" : "")}>
					<div className="layer-popup-inner">
						<div className="content">
							<button type="button" className="btn close" onClick={this.hideBestPopup()}><span className="blind">닫기</span></button>
							<div className="con">
								<div className="detail-ranking groc-rk">
									<div className="row rk-user">
										<div className="img-wrap"><span className="img"><img src={srcString} alt=""/></span></div>
										<div className="name"><span className="txt">{this.state.selectBestProfile.nickName}</span></div>
										<div className="lab-time">
											<span className="num">{(this.state.selectBestProfile.dnfYn === true) ? "N/A" : this.convertTimeReal(this.state.selectBestProfile.xlLaptime + this.state.selectBestProfile.xrLaptime)}</span>
										</div>
									</div>

									<div className="row">
										<div className="track">
											<span className="sm-txt">{t('Groc_l_lap')}</span>
											<span className="num">{(this.state.selectBestProfile.xlDnfYn === true) ? "N/A" : this.convertTimeReal(this.state.selectBestProfile.xlLaptime)}</span>
											{this.state.selectBestProfile.xlFsYn ? <span className="label">FS</span> : null}
										</div>
										<div className="track">
											<span className="sm-txt">{t('Groc_r_lap')}</span>
											<span className="num">{(this.state.selectBestProfile.xrDnfYn === true) ? "N/A" : this.convertTimeReal(this.state.selectBestProfile.xrLaptime)}</span>
											{this.state.selectBestProfile.xrFsYn ? <span className="label">FS</span> : null}
										</div>
										<div className="ranking">
											<span className="sm-txt">{t('Groc_rank')}</span>
											<span className="num">{this.state.selectBestProfile.dnfYn ? "실격" : rankStr}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		}

		var roundPopup = null
		if (this.state.roundProfilePopup) {
			var len = this.state.pointList.length
			var pointsDiv = []
			for (var i=0; i<len; i++) {
				pointsDiv.push(<li>
					<div className={"accordion-ttl" + (this.state.pointOnOffList[i] === true ? " on" : "")} onClick={this.openClosePoint(i)}>
						<div className="ttl">{this.state.pointList[i].raceName + " " + "TRACK " + this.state.pointList[i].courseName}</div>
						<div className="lab-time">
							<span className="num">{this.state.pointList[i].point}</span>
							<span className="label">RP</span>
						</div>
					</div>
					<div className="accordion-con">
						<div className="point">
							<span className="ttl">랭킹</span>
							<span className="num">{this.state.pointList[i].rankingPoint + "P"}</span>
						</div>
						<div className="point">
							<span className="ttl">미션</span>
							<span className="num">{this.state.pointList[i].missionPoint + "P"}</span>
						</div>
						<div className="point">
							<span className="ttl">테크니컬</span>
							<span className="num">{this.state.pointList[i].bonusPoint + "P"}</span>
						</div>
					</div>
				</li>)
			}
			var srcStr = ""
			if (this.state.selectRoundProfile.imgUrl === null) {
				srcStr = "https://asset.981park.com/www/common/mweb/img/sub/groc/icon-profile-empty-large.png"
			} else {
				srcStr = this.state.selectRoundProfile.imgUrl
			}
			roundPopup = <div className="layer-popup groc-popup groc-round-popup">
				<div className="layer-popup-inner">
					<div className="content">
						<button type="button" className="btn close" onClick={this.hideRoundPopup()}><span className="blind">닫기</span></button>
						<div className="con">
							<div className="detail-ranking groc-rk">
								<div className="row rk-user">
									<div className="img-wrap"><span className="img"><img src={srcStr} alt=""/></span></div>
									<div className="name"><span className="txt">{this.state.selectRoundProfile.nickName}</span></div>
									<div className="lab-time">
										<span className="num">{this.state.selectRoundProfile.point}</span>
										<span className="label">RP</span>
									</div>
								</div>
								<div className="accordion-wrap">
									<div className="accordion-head">
										<div className="ttl">{t('Groc_racing_point')}</div>
									</div>
									<div className="accordion-contents">
										<ul className="accordion">
											{pointsDiv}
										</ul>
									</div>
									<div className="accordion-foot">
										<div className="ttl">{t('Groc_rank')}</div>
										<div className="num">{this.state.selectRoundProfile.ranking + this.getOrdinal(this.state.selectRoundProfile.ranking)}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		}

		var banner = null
		if (this.state.bannerCheck !== true) {
			banner = <div className="sub-groc-banner">
				<a href="https://asset.981park.com/www/common/mweb/img/pdf/groc-rule-2022_1103.pdf" target="_blank" className="btn-groc-rule"><span className="txt">{t('Groc_rule_link_mw_2022')}</span></a>
				<div className="marquee">
					<div className="banner banner1">
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day tex -twoxt">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">GROC 2024 START</div>
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day tex -twoxt">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">GROC 2024 START</div>
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day tex -twoxt">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">GROC 2024 START</div>
					</div>
					<div className="banner banner2">
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day tex -twoxt">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">GROC 2024 START</div>
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day tex -twoxt">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">GROC 2024 START</div>
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/></div>
						<div className="d-day tex -twoxt">D-{this.state.ddayT + this.state.ddayN}</div>
						<div className="txt">GROC 2024 START</div>
					</div>
				</div>
			</div>
		}
		return (
			<>
				<div className={"content-wrap sub-content sub-groc sub-groc-main" + (this.state.ddayCheck ? "" : " sub-groc-red") + (this.state.bannerCheck ? "" : " add-sub-groc-banner")}>
					<div className="sub-groc-main-content">
						{banner}
						<div className="sub-groc-left">
							<div className="tab-menu-wrap">
								<ul className="tab-menu">
									<li className={tabName} onClick={(this.state.ddayCheck === true) ? this.animationStart("real") : this.animationStart("best")} style={{cursor: "pointer"}}><a >{rankingTitle}</a></li>
									{(this.state.ddayCheck === true) ? null : <li className={this.state.tabType === "round" ? "selected" : ""} onClick={this.animationStart("round")} style={{cursor: "pointer"}}><a >{t('Groc_round_top_10')}</a></li>}

								</ul>
							</div>
							<ul className="link-menu">
								<li><Link to={`/`+RoutePath.ABOUTGROC}>{t('Groc_about')}</Link></li>
								<li><Link to={`/`+RoutePath.HALLOFFAME}>{t('Groc_hall')}</Link></li>
							</ul>
						</div>

						<div className="sub-groc-right">
							<div className="tab-con-wrap">
								{contents}
							</div>
						</div>
					</div>
				</div>
				{bestPopup}
				{roundPopup}
			</>
		);
	}
}
export default withTranslation()(NewMGrocComp);