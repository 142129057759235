import React from 'react';

class MMSComponents  extends React.Component {
	constructor(props) {
        super(props);
    }

    componentDidMount() {
        require('../common/mweb/css/index.css');
    }

	render() {
        return (
            <div className="park-wrap mms-groc-wrap">
                <div className="content-wrap">
                    <div className="top-header">
                        <p className="ttl sm-ttl"><span className="txt">GROC 2023 Championship Final</span> 모바일 리플렛</p>
                    </div>
                    <div className="contents">
                        <div className="inner">
                            <div className="logo"><img src="https://asset.981park.com/www/common/mweb/img/sub/groc/graphic-emblem-groc-champion-2023.svg" alt=""/></div>

                            <div className="info">
                                <div className="txt-wrap">
                                    <p className="ttl">대회 개요</p>
                                    <div className="txt">
                                        <p><b>일시</b> | 2023년 12월 3일(일) 08 : 00 ~ 16 : 30</p>
                                        <p><b>장소</b> | 9.81 PARK</p>
                                        <p><b>경기 방식</b> | 오전 1차 주행(L트랙), 오후 2차 주행(R트랙)</p>
                                    </div>
                                    <div className="ps-txt">
                                        <p>* 기록 합산 집계를 통해 순위 결정(1, 2, 3위)</p>
                                        <p>* 오후 2차 주행은 오전 1차 주행 성적의 역순으로 진행</p>
                                    </div>
                                </div>
                                <div className="txt-wrap">
                                    <p className="ttl">TIME TABLE</p>
                                    <div className="time-table">
                                        <table>
                                            <tr>
                                                <th>
                                                    <p className="time">08 : 00</p>
                                                </th>
                                                <td>
                                                    <p className="txt">Finalist 집결 및 등록</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="time">09 : 30</p>
                                                </th>
                                                <td>
                                                    <p className="txt">개회식</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="time">09 : 50</p>
                                                </th>
                                                <td>
                                                    <p className="txt">X팀전 현장 신청 마감</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th rowSpan="2">
                                                    <p className="time">10 : 00</p>
                                                </th>
                                                <td>
                                                    <p className="txt">A~H조 레드카펫 세레모니</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="txt">A~H조 1차 주행(L트랙)</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="time">11 : 00 ~ 13 : 00</p>
                                                </th>
                                                <td>
                                                    <p className="txt">조별 점심 식사 및 Breaktime</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="time">12 : 10 ~ 12 : 50</p>
                                                </th>
                                                <td>
                                                    <p className="txt">2차 주행 조 확인 및 탑승 대기</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th rowSpan="2">
                                                    <p className="time">13 : 00</p>
                                                </th>
                                                <td>
                                                    <p className="txt">1~8조 2차 주행(R트랙)</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="txt">2차 주행 후 현장 인터뷰</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="time">13 : 30</p>
                                                </th>
                                                <td>
                                                    <p className="txt">동반인 GR-D LET’S SCREAM 배틀</p>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    <p className="time">15 : 30</p>
                                                </th>
                                                <td>
                                                    <p className="txt">시상식 및 폐회식</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="time">16 : 30</p>
                                                </th>
                                                <td>
                                                    <p className="txt">대회 종료</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="ps-txt">
                                        <p>* 대회 당일 기상 상황이나 기타 특이사항으로 인해 세부일정은 변경될 수 있습니다.</p>
                                        <p>* 개회식/시상식 및 폐회식은 센트럴(B2F)에서 진행됩니다.</p>
                                        <p>* Finalist분들은 조 확인 후 반드시 주행 10분 전 까지 모여주시기 바랍니다.</p>
                                    </div>
                                </div>
                                <div className="txt-wrap">
                                    <p className="ttl">2차 주행</p>
                                    <div className="txt">
                                        <p>• <b>오후 2차 주행은 오전 1차 주행 성적의 역순으로 진행됩니다.</b></p>
                                        <p>• <b>오후 2차 주행(R트랙) 조 확인은 12:10부터 리셉션(B1F)과 스페이스제로(3F)에서 가능합니다.</b></p>
                                        <p>• <b>반드시 2차 주행 조와 출발 대기 시간을 확인해주시기 바랍니다.</b></p>
                                    </div>
                                </div>
                            </div>

                            <div className="info">
                                <p className="ttl lg-ttl">경기 안내</p>
                                <div className="txt-wrap">
                                    <p className="ttl">경기 진행 방식</p>
                                    <div className="txt">
                                        <p>• 오전 1차 주행(L트랙), 오후 2차 주행(R트랙)</p>
                                        <p>• L/R트랙 기록 합산 집계를 통해 1, 2, 3순위 결정</p>
                                        <p>• 오후 2차 주행 조는 오전 1차 주행 성적에 따라 <br/> 역순으로 배정</p>
                                    </div>
                                    <div className="ps-txt">
                                        <p>* 오전 1차 주행에서 72위를 기록한 FINALIST가 오후 2차 주행 1조 첫번째 순서로 배정됩니다.</p>
                                    </div>
                                    <div className="txt">
                                        <p>• 오후 2차 주행(R트랙) 조 확인은 리셉션(B1F)과 스페이스제로(3F)에서 확인 가능</p>
                                    </div>
                                </div>
                                <div className="txt-wrap">
                                    <p className="ttl">시상</p>
                                    <div className="txt">
                                        <p>• <b>1위</b> | 상품권 50만원 + GROC 트로피 + Champion 자켓 + 골든플레이트</p>
                                        <p>• <b>2위</b> | 상품권 40만원 + GROC 트로피</p>
                                        <p>• <b>3위</b> | 상품권 30만원 + GROC 트로피</p>
                                    </div>
                                </div>
                                <div className="txt-wrap">
                                    <p className="ttl">주행 시 주의사항</p>
                                    <div className="txt">
                                        <p>• <b>심사중(Under Investigation)</b> | ‘충돌’, ‘안전 규정 위반’, ‘지시 불이행’ 등의 실격 의심 사유가 확인된 레이싱 판독 중인 상태</p>
                                        <p>• <b>DNF(Did Not Finish)</b> | 실격 사유가 확인되거나 안전 수칙 불이행 시 미완주 처리되는 실격</p>
                                        <p>• <b>FS(Flying Start)</b> | 신호보다 빠른 부정출발, 정해진 출발 구역 안에서 출발하지 않을 시 패널티 10초 부여</p>
                                        <p>• <b>동률자 랭킹처리</b> | 최고속도 > 평균속도 > 최고횡G 순</p>
                                        <p>• <b>재주행</b> | 부스터 미작동 및 차량이상(조향, 제동) 또는 기록 데이터가 저장되지 않을 경우</p>
                                    </div>
                                </div>
                                <div className="txt-wrap">
                                    <p className="ttl">대회 규정집</p>
                                    <div className="txt">
                                        <p>• GROC 2023 Championship Final 대회 규정은 9.81파크 홈페이지에서 확인하실 수 있습니다.</p>
                                    </div>
                                    <div className="btn-area">
                                        <a href="https://asset.981park.com/www/common/web/img/pdf/groc-rule-2023.pdf" target="_blank" className="btn"><span className="txt">대회 규정 바로가기</span></a>
                                    </div>
                                </div>
                            </div>

                            <div className="info">
                                <p className="ttl lg-ttl">이벤트 안내</p>
                                <div className="txt-wrap">
                                    <p className="ttl">번외 이벤트</p>
                                    <ul className="txt">
                                        <li>
                                            <p><b>1. X리그 팀전 - 이벤트 매치</b></p>
                                            <p>• FINALIST 4인 1팀을 이루어 팀의 오전 주행 + 오후 주행 기록 합산이 가장 빠른 1팀 시상</p>
                                            <p>• 신청 기간 : ~ 12월 3일(일) 오전 9시 50분까지</p>
                                        </li>
                                    </ul>
                                    <div className="btn-area">
                                        <a href="https://eziyeon.notion.site/GROC-2023-Championship-Final-X-f71cf72980604e16938500cfa3f9ce3a?pvs=4" target="_blank" className="btn"><span className="txt">참가 신청 안내 바로가기</span></a>
                                    </div>
                                    <ul className="txt">
                                        <li>
                                            <p><b>2. 3코스 GR-D LET’S SCREAM 배틀</b></p>
                                            <p>• 주행 시간 : 13:30 ~ 14:30</p>
                                            <p>• FINALIST 동반인을 대상, GR-D코스 주행 후 전체 기록 1위 시상</p>
                                            <p className="light-txt">* 3코스 미션 ‘소리질러 게임’ : 출발 직후, 디스플레이 화면에 ‘BOOSTER’가 뜰 때까지 힘차게 소리를 지르면 초반 가속을 도와주는 ‘스크림 부스터’ 획득</p>
                                            <p>• 신청 기간 : ~ 12월 3일(일) 13시까지</p>
                                            <p>• 참가 신청 방법 : 리셉션(B1F)에서 등록</p>
                                        </li>
                                        <li>
                                            <p><b>3. 베스트 레드카펫(BGM/입장퍼포먼스)</b></p>
                                            <p>• 레드카펫 세레머니 시 입장 BGM과 가장 잘 어울리는 FINALIST 1인 시상</p>
                                        </li>
                                    </ul>
                                    <div className="ps-txt">
                                        <p>* 시상 상품은 시상식때 공개됩니다.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="info">
                                <p className="ttl lg-ttl">혜택 안내</p>
                                <div className="txt-wrap">
                                    <p className="ttl">APP 쿠폰 (12/2, 12/3)</p>
                                    <div className="txt">
                                        <p>• <b>연습 주행 쿠폰(FINALIST)</b> | 12월 2일(토) L/R트랙 각 1회, 총 2회 주행 쿠폰</p>
                                        <p>• <b>중식 & 음료 쿠폰</b> | 12월 3일(일), 1인 2매</p>
                                        <p>• <b>레이스 981 50% 할인쿠폰</b> | 12월 3일(일), 10매(MMS 발송)</p>
                                    </div>
                                </div>
                                <div className="txt-wrap">
                                    <p className="ttl">현장 제공 티켓 (12/3)</p>
                                    <div className="txt">
                                        <p>• <b>FINALIST 전용 티켓 2장</b></p>
                                        <p>➊ <b>경기용</b> | RACE 2회(L/R 자유)</p>
                                        <p>➋ <b>실내용</b> | 스포츠랩 3회 + 링˚˚고 1회 + 프로아레나 1회</p>
                                        <p>• <b>동반인 티켓 1장(동반 1인)</b></p>
                                        <p>➊ <b>번외 이벤트용</b> | GR-D(1인) 탑승권 + (실내용) 스포츠랩 3회 + 링˚˚고 1회 + 프로아레나 1회</p>
                                    </div>
                                </div>
                                <div className="txt-wrap">
                                    <p className="ttl">GROC 2023 Championship FINALIST <br/> 제공 혜택</p>
                                    <div className="txt">
                                        <p>• <b>초청지원금</b> : 30만원<span
                                            className="light-txt">(시상식 종료 후 제세공과금 제외하여 지급)</span></p>
                                        <p>• <b>참가 기념품</b> : 가방 + 모자 + 장갑 + 네임카드 + 머플러 + GROC 모델링 키트</p>
                                        <p>• <b>개인 락커룸 제공</b> : 1인 1개 락커룸 제공(초기 비밀번호 : 0000)</p>
                                    </div>
                                </div>
                            </div>

                            <div className="info">
                                <p className="ttl lg-ttl">식사 안내</p>
                                <div className="txt-wrap">
                                    <p className="ttl">점심 식사(11 : 00 ~ 13 : 00)</p>
                                    <div className="txt">
                                        <p>• <b>스페이스 제로(3F)</b> | 쿠폰 확인 후 도시락 제공 (1인 2개)</p>
                                    </div>
                                    <div className="ps-txt">
                                        <p>* 조별 식사시간을 확인 및 준수해주세요.</p>
                                    </div>
                                    <div className="table sm-table">
                                        <table>
                                            <tr>
                                                <th>
                                                    <p className="ttl">A조, B조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">11 : 00 ~ 11 : 30</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="ttl">C조, D조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">11 : 30 ~ 12 : 00</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="ttl">E조, F조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">12 : 00 ~ 12 : 30</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="ttl">G조, H조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">12 : 30 ~ 13 : 00</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="info">
                                <p className="ttl lg-ttl">기타 안내</p>
                                <div className="txt-wrap">
                                    <div className="txt">
                                        <p>• 입장 시 혼잡이 예상되오니, 가급적 오전 8시에 도착 부탁드립니다.</p>
                                        <p>• FINALIST 등록 마감 시간(오전 9시 20분)까지 방문하지 않으시면 대회 참여가 불가하오니 FINALIST 등록 마감시간 전까지 리셉션(B1F)에서 등록 부탁드립니다. (신분증 지참 필수)</p>
                                        <p>• 개인 물품은 FINALIST 락커에 보관해 주시고 실내에서는 네임카드를 반드시 착용해 주시기 바랍니다.</p>
                                        <p>• 조별 출발 대기시간을 반드시 준수해 주시기 바랍니다.</p>
                                        <p>• 오후 2차 주행은 오전 1차 주행의 성적의 역순으로 진행되며, 2차 주행 조는 점심시간 중(12:10 ~ 13:00)에 리셉션(B1F)과 스페이스제로(3F)에서 확인할 수 있습니다. 2차 주행 전까지 반드시 조를 확인해 주시기 바랍니다.</p>
                                        <p>• 오후 2차 주행이 종료된 FINALIST는 인터뷰존(B2 센트럴)으로 이동하여 인터뷰를 진행해 주시기 바랍니다.</p>
                                        <p>• 초청 지원금은 대회 종료(시상식 종료) 후에 지급되오니 마지막까지 자리를 지켜주시기 바랍니다.</p>
                                        <p>• 대회 당일 현장 상황(기상 등)에 의해 세부 일정은 변경될 수 있으며, 변동 시 별도 안내 드릴 예정입니다.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="info">
                                <p className="ttl lg-ttl">조별 출발 대기 시간</p>
                                <div className="txt-wrap">
                                    <div className="txt">
                                        <p>• 출발 대기 시간을 반드시 준수해 주셔야 레이스 참가가 가능합니다.</p>
                                    </div>
                                    <div className="ps-txt">
                                        <p>* FINALIST는 출발 대기 시간까지 반드시 Finalist 대기존(B2F)으로 모여 주시기 바랍니다.</p>
                                        <p>* 오후 2차 주행(R트랙) 조 확인은 오후 12시 10분부터 리셉션(B1F)과 스페이스제로(3F)에서 확인 가능합니다. 반드시 2차 주행(R트랙) 조와 조별 출발 대기 시간을 확인해 주시기 바랍니다.</p>
                                    </div>
                                    <div className="table">
                                        <table>
                                            <tr>
                                                <th>
                                                    <p className="ttl">A조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">1차 09 : 50</p>
                                                </td>
                                                <th>
                                                    <p className="ttl">1조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">2차 13 : 00</p>
                                                </td>

                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="ttl">B조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">1차 10 : 00</p>
                                                </td>
                                                <th>
                                                    <p className="ttl">2조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">2차 13 : 10</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="ttl">C조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">1차 10 : 10</p>
                                                </td>
                                                <th>
                                                    <p className="ttl">3조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">2차 13 : 20</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="ttl">D조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">1차 10 : 20</p>
                                                </td>
                                                <th>
                                                    <p className="ttl">4조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">2차 13 : 30</p>
                                                </td>

                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="ttl">E조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">1차 11 : 10</p>
                                                </td>
                                                <th>
                                                    <p className="ttl">5조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">2차 14 : 10</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="ttl">F조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">1차 11 : 20</p>
                                                </td>
                                                <th>
                                                    <p className="ttl">6조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">2차 14 : 20</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="ttl">G조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">1차 11 : 30</p>
                                                </td>
                                                <th>
                                                    <p className="ttl">7조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">2차 14 : 30</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p className="ttl">H조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">1차 11 : 40</p>
                                                </td>
                                                <th>
                                                    <p className="ttl">8조</p>
                                                </th>
                                                <td>
                                                    <p className="txt">2차 14 : 40</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="info">
                                <p className="ttl lg-ttl">B1 출입 공간</p>
                                <div className="img-wrap">
                                    <div className="img"><img src="https://asset.981park.com/www/common/mweb/img/mms/img-b-1.svg" alt=""/></div>
                                </div>
                            </div>

                            <div className="info">
                                <p className="ttl lg-ttl">B2 센트럴</p>
                                <div className="img-wrap">
                                    <div className="img"><img src="https://asset.981park.com/www/common/mweb/img/mms/img-b-2.svg" alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}
export default MMSComponents;

