import React from "react";
import {withTranslation} from "react-i18next";
import winners from '../../resource/GROCWinner.json'
import axios from 'axios';
import Slider from "react-slick";


export const DevServer = "https://dev-apis.981park.net"
export const RealServer = "https://apis-jeju.981park.com"

const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'X-Authorization-Client-Secret': '586f474c-50b1-11eb-b88b-02d92b41ce80',
    'X-Authorization-Client-Id': '981park-customer-home'
}

class MHallOffFame extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
            grocIndex: 0,
            lengthIndex: 1, // 마지막 페이지
            indexCount: 5, // 페이지 번호 숫자
            currentIndex: 1, // 현재 페이지
            ranking: [],
            detailProfilePopup: false,
            detailProfile: null
		};
        var seasonList = [] //eslint-disable-line no-unused-vars
	}

    changGROC = index => () => {
		this.setState({grocIndex: index}, this.requestRanking)
	};

    convertTime(milli) {
        if (milli === 0) {
            return "N/A"
        } else {
            let time = new Date(milli);
            let minutes = time.getUTCMinutes();
            let seconds = time.getUTCSeconds();
            let milliseconds = time.getUTCMilliseconds();
            var secondStr = ""
            var milStr = ""
            if (milliseconds < 10) {
                milStr = "00" + milliseconds
            } else if (milliseconds < 100) {
                milStr = "0" + milliseconds
            } else {
                milStr = "" + milliseconds
            }
            if (seconds < 10) {
                secondStr = "0" + seconds
            } else {
                secondStr = "" + seconds
            }
            return minutes + "’" + secondStr + "”" + milStr;
        }
    }

    convertOrdinal(idx) {
        if (idx === 1) {
            return "1st"
        } else if (idx === 2) {
            return "2nd"
        } else if (idx === 3) {
            return "3rd"
        } else {
            return idx + "th"
        }
        
    }

    convertIndex(idx) {
        if (this.state.ranking != undefined) {
            if (this.state.ranking[idx].xlLaptime !== 0 && this.state.ranking[idx].xrLaptime !== 0) {
                return (idx + 1) + ""
            } else {
                return "-"
            }
        } else {
            return "-"
        }
    }

    preIndex = (result) => () => {
        if (result > 0) {
            this.setState({currentIndex: (result - 1) * 5 + 1})
        }
    }

    nextIndex = (result) => () => {
        this.setState({currentIndex: (result + 1) * 5 + 1})
    }

    setIndex = (index) => () => {
        this.setState({currentIndex: index})
    };

    prePhoto = () => () => {
		this.photo.slickPrev();
	}

	nextPhoto = () => () => {
		this.photo.slickNext();
	}

    grocList() {
        if (winners != undefined) {
            var win = []
            for (var k = 0; k < winners.length; k++) {
                const aCell = <li onClick={this.changGROC(k)} style={{cursor: "pointer"}} className={this.state.grocIndex === k ? "selected" : ""} ><a>{winners[k].year}</a></li>
                win.push(aCell) 
            }
            return win
        } else {
            return null
        }
    }

    winnerRoundRanking() {
        var rankings = []  //eslint-disable-line no-unused-vars
        var data = winners[this.state.grocIndex];
        if (data.round.length === 1) {
            return <span className="m-txt">{data.round[0]}</span>
        } else if (data.round.length === 2) {
            return <span className="m-txt">{data.round[0]}<br/>{data.round[1]}</span>
        } else {
            return null
        }
    }

    ranking() {
        var aDivs = []
        if (this.state.ranking != undefined) {
            var rows = []
            for (var i = (this.state.currentIndex - 1) * 10; i < (this.state.currentIndex) * 10; i++) { 
                var rowClass = "row"
                if (i < 3) {
                    rowClass = rowClass + " groc-rk"
                }
                
                var labelClass = "label"
                if (i < this.state.ranking.length) {
                    if (this.state.ranking[i].roundRanking === 1) {
                        labelClass = labelClass + " rk1"
                    }
                    var srcString = ""
                    if (this.state.ranking[i].image === null) {
                        srcString = "https://asset.981park.com/www/common/mweb/img/sub/groc/icon-profile-empty-large.png"
                    } else {
                        srcString = this.state.ranking[i].image
                    }
                    const cell = <div className={rowClass}>
                                    <ul>
                                        <li><div className="num">{this.convertIndex(i)}</div></li>
                                        <li>
                                            <div className="rk-user">
                                                <span className="img-wrap"><span className="img"><img src={srcString} alt=""/></span></span>
                                                <span className="name"><span className="txt">{this.state.ranking[i].nickName}</span></span>
                                            </div>
                                        </li>
                                        <li><div className="num">{this.convertTime(this.state.ranking[i].xlLaptime + this.state.ranking[i].xrLaptime)}</div></li>
                                        <li>
											<div className="btns">
												<button className="btn btn-popup" onClick={this.showDetailPopup(this.state.ranking[i])}><span class="blind">자세히 보기</span></button>
											</div>
										</li>
                                    </ul>
                                </div>
                    rows.push(cell)
                }
            }
            var result = Math.floor(this.state.currentIndex/this.state.indexCount)
            if (this.state.currentIndex % this.state.indexCount === 0) {
                result = result - 1
            }
            var start = (result * this.state.indexCount + 1)
            var last = ((result + 1) * this.state.indexCount + 1)
            if (last > this.state.lengthIndex) {
                last = this.state.lengthIndex + 1
            }
            for (var k = start; k < last; k++) { 
                const aCell = <a style={{cursor: "pointer"}} className={this.state.currentIndex == (k) ? "active" : ""} onClick={this.setIndex(k)}>{k}</a>
                aDivs.push(aCell) 
            }
        }
        return <div className="ranking-list">
					<div className="list">
						<div className="row list-head">
							<ul>
								<li><div className="txt">RK</div></li>
								<li><div className="txt">RACER</div></li>
								<li><div className="txt">TOTAL(L+R)</div></li>
								<li>
									<div className="track"><span className="txt">L TRACK</span></div>
									<div className="track"><span className="txt">R TRACK</span></div>
								</li>
							</ul>
						</div>
						{rows}
					</div>
					<div className="paging">
						<a className={"arrow prev " + ((result === 0) ? "disabled" : "")} onClick={this.preIndex(result)}><span className="blind">prev</span></a>
						{aDivs}
						<a className={"arrow next " + ((last > this.state.lengthIndex) ? "disabled" : "")} onClick={this.nextIndex(result)}><span className="blind">next</span></a>
					</div>
				</div>

    }

    showDetailPopup = (ranking) => () => {
		this.setState({detailProfile: ranking, detailProfilePopup: true})
	}

    hideDetailPopup = () => () => {
		this.setState({detailProfile: null, detailProfilePopup: false})
	}

	componentDidMount() {
        if (window.location.hostname === "dev-home.981park.net") {
            this.server = DevServer
        } else {
            this.server = RealServer
        }
        this.requestSeason()
    }

    requestSeason() {
		axios({
			url:`${this.server}/customer/data/groc/years`,
			method: 'get',
			headers: headers
		  }).then(response => {
			this.seasonList = response.data.data
            this.requestRanking()
		  })
	}

    requestRanking() {
		axios({
			url:`${this.server}/customer/data/groc/finalRanking/${this.seasonList[this.state.grocIndex].schId}`,
			method: 'get',
			headers: headers
		  }).then(response => {
            var last = Math.ceil(response.data.data.length/10)
			this.setState({ranking: response.data.data, lengthIndex: last, currentIndex: 1}) 
		  })
	}

	render() {
		const {t} = this.props;
        const settings = {
            slidesToShow : 1,
			slidesToScroll : 1,
			variableWidth: true,
        };
        var data = winners[this.state.grocIndex];
        var imgDiv =[]
        var photoList = null
        for (var k=0; k<data.images.length; k++) {
            const cell = <div className="photo"><img src={data.images[k]} alt=""/></div>
            imgDiv.push(cell)
        }
        if (data.images.length !== 0) {
            photoList = <div className="groc-photo-list">
                        <div className="photo-inner">
                            <div className="groc-slider-arrow-button">
                                <button onClick={this.prePhoto()} className="photo-arrow-btn photo-arrow-prev"></button>
                                <button onClick={this.nextPhoto()} className="photo-arrow-btn photo-arrow-next"></button>
                            </div>
                        
                            <div className="photo-ttl">{t('Groc_photos')}</div>
                        </div>
                        <div className="photo-list">
                            <Slider ref={c => (this.photo = c)} {...settings}>
                                {imgDiv}
                            </Slider>
                        </div>														
                    </div>
                    
        }

        var detailPopup = null
		if (this.state.detailProfilePopup) {
            var srcString = ""
            if (this.state.detailProfile.image === null) {
                srcString = "https://asset.981park.com/www/common/mweb/img/sub/groc/icon-profile-empty-large.png"
            } else {
                srcString = this.state.detailProfile.image
            }
			detailPopup = <div class="layer-popup groc-popup groc-ranking-popup">
                            <div class="layer-popup-inner">
                                <div class="content">
                                    <button type="button" class="btn close" onClick={this.hideDetailPopup()}><span class="blind">닫기</span></button>
                                    <div class="con">
                                        <div class="detail-ranking groc-rk">
                                            <div class="row rk-user">
                                                <div class="img-wrap"><span class="img"><img src={srcString} alt=""/></span></div>
                                                <div class="name"><span class="txt">{this.state.detailProfile.nickName}</span></div>
                                                <div class="lab-time">
                                                    <span class="num">{this.convertTime(this.state.detailProfile.xlLaptime + this.state.detailProfile.xrLaptime)}</span>
                                                    <span class="label">RP</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="track">
                                                    <span class="sm-txt">{t('Groc_l_lap')}</span>
                                                    <span class="num">{this.convertTime(this.state.detailProfile.xlLaptime)}</span>
                                                </div>
                                                <div class="track">
                                                    <span class="sm-txt">{t('Groc_r_lap')}</span>
                                                    <span class="num">{this.convertTime(this.state.detailProfile.xrLaptime)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
		}
		return (
			<>
			    <div class="content-wrap sub-content sub-groc sub-groc-v2">
					<div class="visual-wrap">
						<div class="visual-slide-wrap">
							<div class="visual-slide-list">
								<div class="slide">
									<div class="img">
										<img src="https://asset.981park.com/www/common/mweb/img/sub/groc/slide-groc-hall-of-fame1.png" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
									</div>
								</div>
							</div>

							<div class="visual-slide-text">
								<div class="inner">
									<div class="con">
										<div class="text-wrap color1">
											<p class="s-text typo1">HALL OF FAME</p>
											<p className="m-text">{t('Groc_hall_title1')} <br/> {t('Groc_hall_title2')}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-menu-wrap">
						<div class="inner">
							<ul class="tab-menu">
								{this.grocList()}
							</ul>
						</div>
					</div>

					<div class="tab-con-wrap">
						<div class="tab-con groc2021 on">
							<div class="groc-list-wrap">
								<div class="inner">
									<div class="groc-list-ttl"><span class="typo1">{data.year} <br/> GROC CHAMPION</span></div>
									<div class="groc-list">
										<div class="groc">
											<div class="groc-inner">
												<div class="groc-chanpion-info">
													<div class="chanpion-img">
														<div class="img-wrap"><img src={data.profile} alt=""/></div>
														<div class="img-txt typo1">CHAMPION</div>
													</div>
													<div class="chanpion-info">
														<div class="chanpion-info-ttl typo1">CHAMPION PROFILE</div>
														<ul class="chanpion-info-list">
															<li>
																<div class="row row-line">
                                                                <div className="ttl">{t('Groc_nick_name')}</div>
                                                                <div className="ttl">{t('Groc_car_number')}</div>
																</div>
															</li>
															<li>
																<div class="row">
																	<div class="txt"><span class="lg-txt">{data.nickName}</span></div>
																	<div class="txt"><span class="lg-txt">{data.car}</span></div>
																</div>
															</li>
															<li>
																<div class="row row-line">
																	<div class="ttl">{t('Groc_lap_time')}</div>
																</div>
															</li>
															<li>
																<div class="row">
																	<div class="txt">
																		<span class="typo2 lgst-txt">{data.lTime}</span>
																		<span class="typo1 s-label-txt">X/L</span>
																	</div>
																	<div class="txt">
																		<span class="typo2 lgst-txt">{data.rTime}</span>
																		<span class="typo1 s-label-txt">X/R</span>
																	</div>
																</div>
															</li>
															<li>
																<div class="row row-line">
																	<div class="ttl">{t('Groc_lap_time')}</div>
																	<div class="ttl">{t('Groc_round_score')}</div>
																</div>
															</li>
															<li>
																<div class="row">
																	<div class="txt">
                                                                    <span className="s-txt">{t('Groc_sub_title_1')} <br/> {t('Groc_sub_title_2')} <br/> {t('Groc_sub_title_3')}</span>
																	</div>
																	<div class="txt">
																		{this.winnerRoundRanking()}
																	</div>
																</div>
															</li>
														</ul>
													</div>
												</div>
											</div>
											{photoList}
										</div>
									</div>
								</div>
							</div>
							<div class="groc-ranking">
								<div class="inner">
									<div class="ttl-area">
										<div class="ttl">{"GROC " + data.year + " " + t('Groc_final_ranking')}</div>
									</div>
									{this.ranking()}
								</div>
							</div>
						</div>
					</div>
				</div>
                {detailPopup}
        </>
		);
	}
}
export default withTranslation()(MHallOffFame);