
import React from "react";
import {withTranslation} from "react-i18next";
import RoutePath from "../../resource/RoutePath";
import { Link } from 'react-router-dom';

class PlayFlexComp  extends React.Component {
	constructor(props) {
		super(props);
		this.play = React.createRef();
		this.state = {
		};
	}

	scrollContent = () => () => {
        this.play.current.scrollIntoView({ behavior: 'smooth'});
    };

	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-wrap">
					<div className="img">
						<img src="https://asset.981park.com/www/common/web/img/sub/play/play-bg3.jpg" alt="트렌디한 공간을 누비며 안에서 놀자! 즐길거리 981"/>
					</div>
					<div className="inner">
						<div className="con">
							<div className="text-wrap color1">
								<p className="s-text typo1">PLAY INFORMATION</p>
								<p className="m-text">{t('PlayFlexComp_title_1')}<br/>{t('PlayFlexComp_title_2')}<br/> {t('PlayFlexComp_title_3')}</p>
							</div>

							<div className="button-wrap" style={{cursor:"pointer"}}>
								<a  onClick={this.scrollContent()} className="btn-bottom typo1 color1">PLAY INFORMATION</a>
							</div>	
						</div>	
					</div>
				</div>
				<div className="card-list-wrap" ref={this.play}>
					<div className="card-wrap">
						<div className="inner">
							<div className="con">
								<div className="card-list">
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/play/card-3-1.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/web/img/sub/play/garage-logo.svg" alt="garage 981"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.FLGARAGE} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>						
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/play/card-3-6.png" alt="9.81 파크" className="img img-1"/>
											<p className="text text-ps-0"><img src="https://asset.981park.com/www/common/web/img/sub/play/img-logo-photodrink.png" alt="photodrink"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.FLPHOTO} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>						
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/play/card-3-5.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/web/img/sub/play/broccollege-logo.svg" alt="broccollege"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.FLBRO} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/play/card-3-7.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/web/img/sub/play/booster-station-logo.svg" alt="booster-station"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.FLBOOSTER} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/play/card-3-8.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/web/img/sub/play/onatz-logo.svg" alt="onatz"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.FLONATZ} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/play/card-3-4.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/web/img/sub/play/loungex-logo.svg" alt="loungex"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.FLLOUNGE} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/play/card-3-2.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/web/img/sub/play/zero-logo.svg" alt="zero"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.FLSPACE} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
								</div>
							</div>
						</div>					
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(PlayFlexComp);