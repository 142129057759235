import React from "react";
import {withTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import RoutePath from '../../resource/RoutePath'

class MHeaderComp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuDP: false,
			subMenu: ""
		}
	}

	showMenu = () => () => {
		this.setState({menuDP:!(this.state.menuDP)}, this.props.topLock(this.state.menuDP));
	}

	toggleMenu = menu => () => {
		if (this.state.subMenu === menu) {
			this.setState({subMenu: ""})
		} else {
			this.setState({subMenu: menu})
		}
    }

	render() {
		const {t} = this.props;
		return (
			<header>
				<div className="head-wrap">
					<h1 className="logo"><Link to={`/`+RoutePath.HOME}><img src="https://asset.981park.com/www/common/mweb/img/common/logo/981-park.svg" alt="981 PARK"/></Link></h1>
					<button type="button" className={"btn btn-menu" + (this.state.menuDP ? " selected": "")} onClick={this.showMenu()}><span className="blind">menu</span></button>
					<nav className="accordion" style={{display: (this.state.menuDP ? "block": "none")}}>
						<div className="inner">
							<ul className="gnb">
								<li>
									<a className={"link-gnb typo1" + (this.state.subMenu === "park" ? " sub-menu-open" : "")} onClick={this.toggleMenu("park")}>PARK</a>
									<div className="sub-menu" style={{display: (this.state.subMenu === "park") ? "block" : "none"}}>
										<Link to={`/`+RoutePath.PARK} className="depth-1" onClick={this.showMenu()}>PARK INFORMATION</Link>
										<ul className="depth-3-wrap">
											<li><Link to={`/`+RoutePath.ACTIVITY} onClick={this.showMenu()}>{t('Park_sub1_title')}</Link></li>
											<li><Link to={`/`+RoutePath.PARKFLEX} onClick={this.showMenu()}>{t('Park_sub2_title')}</Link></li>
											<li><Link to={`/`+RoutePath.CONVENIENT} onClick={this.showMenu()}>{t('Park_sub3_title')}</Link></li>
											<li><Link to={`/`+RoutePath.SHUTTLE} onClick={this.showMenu()}>{t('Park_sub4_title')}</Link></li>
											<li><Link to={`/`+RoutePath.OPERATION} onClick={this.showMenu()}>{t('Park_sub5_title')}</Link></li>
										</ul>
									</div>
								</li>
								<li>
									<a className={"link-gnb typo1" + (this.state.subMenu === "play" ? " sub-menu-open" : "")} onClick={this.toggleMenu("play")}>PLAY</a>
									<div className="sub-menu" style={{display: (this.state.subMenu === "play") ? "block" : "none"}}>
										<Link to={`/`+RoutePath.PLAY} className="depth-1" onClick={this.showMenu()}>PLAY INFORMATION</Link>
										<ul className="depth-2-wrap">
											<li>
												<Link to={`/`+RoutePath.PLAY} onClick={this.showMenu()}>OUTDOOR ACTIVITY</Link>
												<ul className="depth-3-wrap">
													<li><Link to={`/`+RoutePath.OUTRACE981} onClick={this.showMenu()}>{t('Play_sub1_title')}</Link></li>
													<li><Link to={`/`+RoutePath.OUTHEAL} onClick={this.showMenu()}>{t('Play_sub2_title')}</Link></li>
												</ul>
											</li>
											<li>
												<Link to={`/`+RoutePath.INDOOR} onClick={this.showMenu()}>INDOOR ACTIVITY</Link>
												<ul className="depth-3-wrap">
													<li><Link to={`/`+RoutePath.INMETA} onClick={this.showMenu()}>{t('Play_sub4_title')}</Link></li>
													<li><Link to={`/`+RoutePath.INPROARENA} onClick={this.showMenu()}>{t('Play_sub32_title')}</Link></li>
													<li><Link to={`/`+RoutePath.INLAB} onClick={this.showMenu()}>{t('Play_sub6_title')}</Link></li>
													<li><Link to={`/`+RoutePath.INRINGGO} onClick={this.showMenu()}>{t('Play_sub5_title')}</Link></li>
													<li><Link to={`/`+RoutePath.INARENA} onClick={this.showMenu()}>{t('Play_sub3_title')}</Link></li>
												</ul>
											</li>
											<li>
												<Link to={`/`+RoutePath.PLAYFLEX} onClick={this.showMenu()}>FLEX &amp; RELAX</Link>
												<ul className="depth-3-wrap">
													<li><Link to={`/`+RoutePath.FLGARAGE} onClick={this.showMenu()}>{t('Play_sub7_title')}</Link></li>
													<li><Link to={`/`+RoutePath.FLPHOTO} onClick={this.showMenu()}>{t('Play_sub11_title')}</Link></li>
													<li><Link to={`/`+RoutePath.FLBRO} onClick={this.showMenu()}>{t('Play_sub9_title')}</Link></li>
													<li><Link to={`/`+RoutePath.FLBOOSTER} onClick={this.showMenu()}>{t('Play_sub12_title')}</Link></li>
													<li><Link to={`/`+RoutePath.FLONATZ} onClick={this.showMenu()}>{t('Play_sub13_title')}</Link></li>
													<li><Link to={`/`+RoutePath.FLLOUNGE} onClick={this.showMenu()}>{t('Play_sub10_title')}</Link></li>
													<li><Link to={`/`+RoutePath.FLSPACE} onClick={this.showMenu()}>{t('Play_sub8_title')}</Link></li>
												</ul>
											</li>
										</ul>
									</div>
								</li>
								<li>
									<a className={"link-gnb typo1" + (this.state.subMenu === "race" ? " sub-menu-open" : "")} onClick={this.toggleMenu("race")}>RACE 981</a>
									<div className="sub-menu" style={{display: (this.state.subMenu === "race") ? "block" : "none"}}>
										<Link to={`/`+RoutePath.RACE981} className="depth-1" onClick={this.showMenu()}>RACE 981 INFORMATION</Link>
										<ul className="depth-3-wrap">
											<li><Link to={`/`+RoutePath.GRAVITYRACER} onClick={this.showMenu()}>{t('Race_sub1_title')}</Link></li>
											<li><Link to={`/`+RoutePath.LICENSE} onClick={this.showMenu()}>{t('Race_sub2_title')}</Link></li>
											<li><Link to={`/`+RoutePath.BATTLE} onClick={this.showMenu()}>{t('Race_sub3_title')}</Link></li>
											{/* <li><Link to={`/`+RoutePath.RANKING} onClick={this.showMenu()}>{t('Race_sub4_title')}</Link></li> */}
											<li><Link to={`/`+RoutePath.RANKINGRULE} onClick={this.showMenu()}>{t('Race_sub5_title')}</Link></li>
										</ul>
									</div>
								</li>
								<li>
									<a className={"link-gnb typo1" + (this.state.subMenu === "groc" ? " sub-menu-open" : "")} onClick={this.toggleMenu("groc")}>GROC</a>
									<div className="sub-menu" style={{display: (this.state.subMenu === "groc") ? "block" : "none"}}>
										<Link to={`/`+RoutePath.GROC} className="depth-1" onClick={this.showMenu()}>GROC INFORMATION</Link>
										<ul className="depth-3-wrap">
											<li><Link to={`/`+RoutePath.ABOUTGROC} onClick={this.showMenu()}>{t('Groc_about')}</Link></li>
											<li><Link to={`/`+RoutePath.HALLOFFAME} onClick={this.showMenu()}>{t('Groc_hall')}</Link></li>
										</ul>
									</div>
								</li>
								<li>
									<a className={"link-gnb typo1" + (this.state.subMenu === "ticket" ? " sub-menu-open" : "")} onClick={this.toggleMenu("ticket")}>TICKET</a>
									<div className="sub-menu" style={{display: (this.state.subMenu === "ticket") ? "block" : "none"}}>
										<Link to={`/`+RoutePath.TICKET} className="depth-1" onClick={this.showMenu()}>TICKET INFORMATION</Link>
										<ul className="depth-3-wrap">
											<li><Link to={`/`+RoutePath.GROUP} onClick={this.showMenu()}>{t('Ticket_sub1_title')}</Link></li>
											<li><Link to={`/`+RoutePath.TEAMROOM} onClick={this.showMenu()}>{t('Ticket_sub2_title')}</Link></li>
										</ul>
									</div>
								</li>
							</ul>
							<ul className="sns-list">
								<li><a href={'https://www.facebook.com/981park'} target="_blank" className="sns"><img src="https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-menu-btn-01-facebook.svg" alt="facebook"/></a></li>
								<li><a href={'https://www.instagram.com/9.81park/'} target="_blank" className="sns"><img src="https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-menu-btn-02-instar.svg" alt="instar"/></a></li>
								<li><a href={'https://www.youtube.com/channel/UCdPUWUu5ZOWegvaqqwj7hfg'} target="_blank" className="sns"><img src="https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-menu-btn-03-youtube.svg" alt="youtube"/></a></li>
								<li><a href={'https://blog.naver.com/981jeju'} target="_blank" className="sns"><img src="https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-footer-btn-04-blog.svg" alt="blog"/></a></li>
							</ul>
						</div>
					</nav>
				</div>    		
			</header>
		);
	}
}
export default withTranslation()(MHeaderComp);