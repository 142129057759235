import React from "react";
import {withTranslation} from "react-i18next";
class MTopBannerComp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentWillMount() {

	}

	componentDidMount() {
		// var fs=document.getElementById("qqq");
	}

	render() {
		const {t} = this.props;
		return (
			<div className="banner-wrap">
				<div className="inner">
				<ul className="banner-list">
					<li>
						<div className="txts txt-line01">
							<span className="txt">9.81 파크 모든 공간에서 마스크를 착용해주세요.</span>
						</div>
					</li> 
				</ul>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MTopBannerComp);